import SynchroHelper from "@/services/helpers/synchroHelper";
import { RST_EVENT, REF_EVENT, RST_COMPETITOR_NOTES, RST_COMPETITOR_COMPULSORY_NOTE, RST_JUDGE_EVENT_COMPULSORY_NOTE, RST_JUDGE_EVENT_NOTES, RST_LOG, RST_EVENT_COMPETITOR, RST_COMPETITOR, RST_COMPETITION, RST_ROUND  } from "@/LocalDB"
import { RST_COMPULSORY_NOTE, RST_ROUTINE_NOTE, RST_BALLET_NOTE } from "@/LocalDB";
import { v4 as uuid} from 'uuid';
import { store } from "@/services/store";
import axios from "axios";

const CompetitorsNotesHelper = {

  async getJudgeCompulsoryNote (competition_id, round_number, level_code, category_code, event_code, competitor_id, compulsory_id, staff_id){
    var ret = RST_COMPULSORY_NOTE.query()
                .where("competition_id", competition_id)
                .where("round_number", round_number)
                .where("level", level_code)
                .where("category", category_code)
                .where("event", event_code)
                .where('competitor_id', competitor_id)
                .where("compulsory_id", compulsory_id)
                .where("judge_id", staff_id)
                .first();
    return ret;
  },

  getCompulsoryNote (competition_id, round_number, level_code, category_code, competitor_id, compulsory_id){
    var ret = RST_COMPETITOR_COMPULSORY_NOTE.query()
                .where("competition_id", competition_id)
                .where("round_number", round_number)
                .where("level", level_code)
                .where("category", category_code)
                .where('competitor_id', competitor_id)
                .where("compulsory_id", compulsory_id)
                .first();
    return ret;
  },
  ensureCompulsoryNote (competition_id, round_number, level_code, category_code, competitor_id, compulsory_id){
    var ret = this.getCompulsoryNote (competition_id, round_number, level_code, category_code, competitor_id, compulsory_id);
    if(ret == null)
    {
      ret = {
        id: uuid(),
        competition_id: competition_id,
        round_number: round_number,
        level: level_code,
        category: category_code,
        competitor_id: competitor_id,
        compulsory_id: compulsory_id,
        isNew: true,
      };
      RST_COMPETITOR_COMPULSORY_NOTE.insertOrUpdate({ data : ret });
      SynchroHelper.LogOperation("INSERT", "RST_COMPETITOR_COMPULSORY_NOTE", null, ret);
    }
    return ret;
  },

  getNotes(competition_id, round_number, level_code, category_code, competitor_id){
    var ret = RST_COMPETITOR_NOTES.query()
                .where("competition_id", competition_id)
                .where("round_number", round_number)
                .where("level", level_code)
                .where("category", category_code)
                .where('competitor_id', competitor_id)

                .first();
    return ret;
  },
  ensureNotes(competition_id, round_number, level_code, category_code, competitor_id){
    var ret = this.getNotes(competition_id, round_number, level_code, category_code, competitor_id);
    if(ret == null)
    {
      ret = {
        id: uuid(),
        competition_id: competition_id,
        round_number: round_number,
        level: level_code,
        category: category_code,
        competitor_id: competitor_id,
       };
      RST_COMPETITOR_NOTES.insert({ data: ret});
      SynchroHelper.LogOperation("INSERT", "RST_COMPETITOR_NOTES", null, ret);
    }
    return ret;
  },

  razEventNotes(competition_id, round_number, level_code, category_code, event_code)
  {
    var event = RST_EVENT.query()
                  .where("competition_id", competition_id)
                  .where("round_number", round_number)
                  .where("level", level_code)
                  .where("category", category_code)
                  .where("event", event_code)
                  .first();
    if(event == null)
      return false;

    // On peut supprimer sans vergogne et sans se poser de question toutes les notes qui auraient été enregistrées au titre
    // d'une figure de précision dans le cadre de cette épreuve par quelque juge et pour quelque compétiteur que ce soit.
    RST_JUDGE_EVENT_COMPULSORY_NOTE.query()
      .where("competition_id", competition_id)
      .where("round_number", round_number)
      .where("level", level_code)
      .where("category", category_code)
      .where("event", event_code)
      .get()
      .forEach(judgeNote => {
          RST_JUDGE_EVENT_COMPULSORY_NOTE.delete(judgeNote.id);
          SynchroHelper.LogOperation("DELETE", "RST_JUDGE_EVENT_COMPULSORY_NOTE", judgeNote.id);
      });

    // De la même manière on peut supprimer toutes les notes posées par n'importe quel juge et pour n'importe quel compétiteur
    // dans le cadre de l'épreuve.
    RST_JUDGE_EVENT_NOTES.query()
      .where("competition_id", competition_id)
      .where("round_number", round_number)
      .where("level", level_code)
      .where("category", category_code)
      .where("event", event_code)
      .get()
      .forEach(judgeNotes => {
        RST_JUDGE_EVENT_NOTES.delete(judgeNotes.id);
        SynchroHelper.LogOperation("DELETE", "RST_JUDGE_EVENT_NOTES", judgeNotes.id);
      });

    // Il reste maintenant à supprimer les totaux correspondant aux éléments notés dans l'épreuve à remettre à zéro.
    // On obtient cette information en interrogeant la table de référence des épreuves.
    var refEvent = REF_EVENT.query().where("code", event_code).first();

    // Si l'épreuve comprend les figures, on doit virer le total par figure...
    RST_COMPETITOR_COMPULSORY_NOTE.query()
      .where("competition_id", competition_id)
      .where("round_number", round_number)
      .where("level", level_code)
      .where("category", category_code)
      .get()
      .forEach(compulsoryTotalNote => {
        RST_COMPETITOR_COMPULSORY_NOTE.delete(compulsoryTotalNote.id);
        SynchroHelper.LogOperation("DELETE", "RST_COMPETITOR_COMPULSORY_NOTE", )
      });

    // Pour la suite et fin du traitement il faut opérer de façon un peu plus chirurgicale en bouclant sur chaque pilote
    // défini pour la manche.
    // On va également avoir besoin des pondérations applicables en fonction du format retenu pour la manche.
    // On doit donc aller chercher la cétégorie pour récupérer cette information.
    var rules = event.CATEGORY.RULE;

    RST_COMPETITOR_NOTES.query()
      .where("competition_id", competition_id)
      .where("round_number", round_number)
      .where("level", level_code)
      .where("category", category_code)
      .get()
      .forEach(competitorTotals =>
      {
        // Si l'épreuve conprend les figures, on doit réinitialiser le total des figures ainsi que les rangs associés
        // ce qui implique également de réinitialiser le total de la précision et bien sûr le Overall
        if(refEvent.hasCompulsories)
        {
          var compulsoriesChanges = {
            compulsories_total: null,
            compulsories_rank: -1,
            compulsories_federal_Rank: -1,
            compulsories_isCompleted: false,
            precision_total: null,
            precision_total_rank: -1,
            precision_total_federal_rank: -1,
            precision_isCompleted: false,
            overall: null,
            overall_rank: -1,
            overall_federal_rank: -1,
            isCompleted: false,
          };
          RST_COMPETITOR_NOTES.update({ where: competitorTotals.id, data: compulsoriesChanges});
          SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", competitorTotals.id, compulsoriesChanges, "RESET_COMPULSORIES");
        }

        // On applique le même genre de traitement aux données de routine si l'épreuve comprends cet élément.
        if(refEvent.hasRoutine){
          var routineChanges = {
            routine_execution: null,
            routine_execution_rank: -1,
            routine_execution_federal_rank: -1,
            routine_content: null,
            routine_content_rank: -1,
            routine_content_federal_rank: -1,
            routine_total: null,
            routine_total_rank: -1,
            routine_total_federal_rank: -1,
            routine_isCompleted: false,
            precision_total: null,
            precision_total_rank: -1,
            precision_total_federal_rank: -1,
            precision_isCompleted: false,
            overall: null,
            overall_rank: -1,
            overall_federal_rank: -1,
            isCompleted: false,
          }
          RST_COMPETITOR_NOTES.update({ where: competitorTotals.id, data: routineChanges});
          SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", competitorTotals.id, routineChanges, 'RESET_ROUTINE');
        }

        // On fait ensuite la même chose pour les données de ballet si l'épreuve comprends cet élément.
        if(refEvent.hasBallet)
        {
          var balletChanges = {
            ballet_execution: null,
            ballet_execution_rank: -1,
            ballet_execution_federal_rank: -1,
            ballet_choreo: null,
            ballet_choreo_rank: -1,
            ballet_choreo_federal_rank: -1,
            ballet_total: null,
            ballet_total_rank: -1,
            ballet_total_federal_rank: -1,
            ballet_isCompleted: false,
            overall: null,
            overall_rank: -1,
            overall_federal_rank: -1,
            isCompleted: false,
          }
          RST_COMPETITOR_NOTES.update({ where: competitorTotals.id, data: balletChanges});
          SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", competitorTotals.id, balletChanges, 'RESET_BALLET');
        }

        // On peut maintenant recalculer les totaux "temporaires" restant disponibles.
        // Mais avant cela il faut raffraîchir la vision que l'on a de l'enregistrement
        var uptodate = RST_COMPETITOR_NOTES.find(competitorTotals.id);
        var totalPrecision = (uptodate.compulsories_total * (1-rules.poundOfRoutineWithinPresicion)) + (uptodate.routine_total * rules.poundOfRoutineWithinPresicion);
        var totalOverall = (totalPrecision * rules.poundOfPrecisionWithinOverall) + (uptodate.ballet_total * rules.poundOfBalletWithinOverall);
        var totalsChange = {
          precision_total: totalPrecision,
          overall: totalOverall
        };
        RST_COMPETITOR_NOTES.update({ where: uptodate.id, data: { totalsChange }});
        SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", uptodate.id, totalsChange, 'TOTALS' );
      });

    // Il ne reste plus qu'à recalculer les différents rangs (lorque cela est possible).
    // Pour ce faire, on repart de la dernière version des éléments que nous venons de calculer.

    // Puis on les tri sous différents axes pour calculer et stocker l'ordre correspondant.
    // 1 : sur total figures (fédéral uniquement)
    //totals = this.sortOnCompulsoriesTotal(totals, true);
    // 2 : sur total figures (tout pilote confondu)
    //totals = this.sortOnCompulsoriesTotal(totals);
    //totals = this.sortOn
  },

  initializeEvent(competition_id, round_number, level_code, category_code, event_code){
    var event = RST_EVENT.query()
      .where("competition_id", competition_id)
      .where("round_number", round_number)
      .where("level", level_code)
      .where("category", category_code)
      .where("event", event_code)
      .first();

    event.EFFECTIVE_COMPETITORS.forEach(competitor => {
      this.ensureNotes(competition_id, round_number, level_code, category_code, competitor.competitor_id);
    });
  },

  razCompetitorCompulsoryNotes(competition_id, round_number, level_code, category_code, event_code, competitor_id, compulsory_id)
  {
    RST_JUDGE_EVENT_COMPULSORY_NOTE.query()
      .where("competition_id", competition_id)
      .where("round_number", round_number)
      .where("level", level_code)
      .where("category", category_code)
      .where("event", event_code)
      .where("competitor_id", competitor_id)
      .where("compulsory_id", compulsory_id)
      .get()
      .forEach(item => {
          RST_JUDGE_EVENT_COMPULSORY_NOTE.delete(item.id);
          SynchroHelper.LogOperation("DELETE", "RST_JUDGE_EVENT_COMPULSORY_NOTE", item.id, null);
      });

    RST_COMPETITOR_COMPULSORY_NOTE.query()
      .where("competition_id", competition_id)
      .where("round_number", round_number)
      .where("level", level_code)
      .where("category", category_code)
      .where("competitor_id", competitor_id)
      .where("compulsory_id", compulsory_id)
      .get()
      .forEach(note => {
        var change = { noteEntry: null, noteValue: null, rank: null, federal_rank: null};
        RST_COMPETITOR_COMPULSORY_NOTE.update({ where: note.id, data: change});
        SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_COMPULSORY_NOTE", null, change);
      });
  },


  /** OBSOLETE ? */
  computeCompulsory(Event, competitor_id, compulsory_id)
  {
    var compulsoryNote = this.ensureCompulsoryNote(Event.competition_id, Event.round_number, Event.level, Event.category, competitor_id, compulsory_id);

    // Recherche de toutes les notes concernant la figure de précision pour le compétiteur en cours
    var judgesNotes = RST_JUDGE_EVENT_COMPULSORY_NOTE.query()
                              .where("competition_id", Event.competition_id)
                              .where("round_number", Event.round_number)
                              .where("level", Event.level)
                              .where("category", Event.category)
                              .where("event", Event.event)
                              .where("competitor_id", competitor_id)
                              .where("compulsory_id", compulsory_id)
                              .where("isCompleted", true)
                              .get();

    // Si tous les juges ont noté la figure pour le compétiteur en cours...
    if(judgesNotes.length == Event.JUDGES.length)
    {
      //on peut calculer sa note finale pour ladite figure
      // Pour commencer, on élimine les notes des juges d'ombre
      var keptCompulsoryNotes = judgesNotes.filter(note => { return !note.RST_JUDGE.isShadow});

      // Si nécessaire, on trie les notes restantes pour en éliminer la plus basse et/ou la plus haute si
      // les règles en ce sens sont activées.
      // On ne s'intéresse, bien sûr, qu'aux notes qui ne sont pas 'A'
      if(Event.skipWorseNote === true || Event.skipBestNote === true) {
        var notA = keptCompulsoryNotes.filter(note => { return note.noteValue != null});
        if(notA.length == 0)
        {
          return false;
        }
        var sortedCompulsoryNotes = notA.sort((a, b) => Math.sign(b.noteValue - a.noteValue));
        if(Event.skipWorseNote == true && Event.skipBestNote && sortedCompulsoryNotes.length > 2 ){
          sortedCompulsoryNotes.splice(0,1);
          sortedCompulsoryNotes.splice(sortedCompulsoryNotes.length - 1);
        } else {
          if(Event.skipWorseNote && sortedCompulsoryNotes.length > 1)
            sortedCompulsoryNotes.splice(0,1);
          if (Event.skipBestNote == true && sortedCompulsoryNotes.length > 1)
            sortedCompulsoryNotes.splice(sortedCompulsoryNotes.length - 1);
        }
        keptCompulsoryNotes = sortedCompulsoryNotes;
      }
      else {
        keptCompulsoryNotes = keptCompulsoryNotes.filter(note => { return note.noteValue != null});
      }
      if(keptCompulsoryNotes.length > 0)
      {
        var total = (keptCompulsoryNotes.length > 1)
                  ? keptCompulsoryNotes
                    .map(item => item.noteValue)
                    .reduce((a,b) => a + b) / keptCompulsoryNotes.length
                  : keptCompulsoryNotes[0].noteValue;
//        var dbNote = this.getCompulsoryNote(Event.competition_id, Event.round_number, Event.level, Event.category, competitor_id, compulsory_id);
        var change = {
          noteValue: total,
          isCompleted: true,
        };
        RST_COMPETITOR_COMPULSORY_NOTE.update({ where: compulsoryNote.id, data: change });
        SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_COMPULSORY_NOTE", compulsoryNote.id, change);

        this.sortCompetitorCompulsoryNoteResults(Event, compulsory_id, (index) => { return { rank: index, federal_rank: index}});

        // On peut maintenant regarder s'il y a lieu de calculer le total de toutes les figures de précisionn du compétiteur
        this.computeCompulsories(Event, competitor_id);
      }
    }
  },

  /** OBSOLETE ? */
  computeCompulsories(Event, competitor_id)
  {
    var notes = this.ensureNotes(Event.competition_id, Event.round_number, Event.level, Event.category, competitor_id);

    var judgesNotes = RST_COMPETITOR_COMPULSORY_NOTE.query()
              .where("competition_id", Event.competition_id)
              .where("round_number", Event.round_number)
              .where("level", Event.level)
              .where("category", Event.category)
              .where('competitor_id', competitor_id)
              .where(note => { return note.noteValue != null})
              .get();

    // Si toutes les figures ont été notées...
    if(judgesNotes.length == Event.RULE.numberOfCompulsories)
    {
      var total = judgesNotes.map(item => item.noteValue * Event.RULE.poundOfEachCompulsoryWithinPrecision ).reduce((a,b) => { return a+b}) / ( 1 - Event.RULE.poundOfRoutineWithinPrecision);/// Event.RULE.numberOfCompulsories;
      //var note = this.getNotes(Event.competition_id, Event.round_number, Event.level, Event.category, competitor_id);
      var change = {
        compulsories_total: total,
        compulsories_isCompleted: true,
      };
      RST_COMPETITOR_NOTES.update({ where: notes.id, data: change });
      SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", notes.id, change, "COMPULSORIES");

      this.sortCompetitorNotesResults(Event, "compulsories_total", (index) => { return { compulsories_rank: index, compulsories_federal_rank: index }},"COMPULSORIES_RANK");


      // On peut également mettre à jour le total Precision (si possible)
      this.computePrecision(Event, competitor_id);
    }

  },

  /** Met à jour l'ordre de passage effectif (réel) du compétiteur */
  setCurrentCompetitorEffectiveOrder(EVENT){
    if(EVENT.current_competitor_id != null){
      var lastCompetitor = RST_EVENT_COMPETITOR.query().where(c=>c.executionStatus == 'C').orderBy(c => -c.effective_order).first();
      var lastOrder = (lastCompetitor?.effective_order || 0) + 1;
      var upd = {
        executionStatus: 'R',
        effective_order: lastOrder
      };
      var recordId = EVENT.CURRENT_COMPETITOR.id;
      RST_EVENT_COMPETITOR.update({where: recordId, data: upd});
      return SynchroHelper.LogOperation("UPDATE", "RST_EVENT_COMPETITOR", recordId, upd, "EFFECTIVE_ORDER");
    }
    return null;
  },

//#region Compulsory Note entry and computation (savings)

  /**
   * Raffraichit les notes entrées ou calculées dans le cadre de l'épreuve indiquée en paramètre
   * @param {RST_EVENT} EVENT 
   */
  async refreshEventCompulsoriesNotesAsync(EVENT)
  {
    // Pour ne pas risquer d'écraser des notes saisies sur l'appareil courrant et pas encore reporétées sur le serveur,
    // on ne s'autorise le raffraichissement qu'à la condition qu'il n'y ait pas de log en cours pour cet objet.
    // Le premier test vérifie si il existe des logs
    // Si c'est le cas, on tente de les synchroniser avant de tester de nouveau l'existence de logs.
    // Si il y a toujours des logs après cela, on ne fait tente pas de mise à jour des données.
    if(RST_LOG.query().where('operation', 'UPDATE').where('object_type', 'RST_COMPULSORY_NOTE').exists()){
      await SynchroHelper.TryToSynchronizeAsync();
      if(RST_LOG.query().where('operation', 'UPDATE').where('object_type', 'RST_COMPULSORY_NOTE').exists())
        return false;
    }
    var baseUrl = store.getters["config/serverBaseUrl"];
    var url = baseUrl + '/api/competitions/getCompulsoriesNoteValues.php';
    
    try{
      const response = await axios.post(url, { 
        competition_id: EVENT.competition_id, 
        round_number: EVENT.round_number,
        level: EVENT.level,
        category: EVENT.category,
        event: EVENT.event,
      });
      //console.log('refreshEventCompulsoriesNotesAsync -> response', response);
      if(response.data && response.data.compulsoryNotes && response.data.compulsoryNotes.length > 0){
        RST_COMPULSORY_NOTE.update({data: response.data.compulsoryNotes});
        return true;
      }
      return false;
    } catch(error){
      console.log('refreshEventCompulsoriesNotesAsync ERROR ', error);
      return false;
    }
  },

  /**
   * Save a note set by a judge for a Compulsory executed by a competitor
   * @param {RST_EVENT} EVENT - The event during while the compulsory is executed
   * @param {String} staff_id - Id of the judge
   * @param {*} note - Note given by the judge for the compulsory. Can be numeric or "A" (average)
   * @param {*} total - Final note given by the judge, including the potential points substractions due to penalties 
   * @param { Object } penalties - An object containing the details of the penalties set.
   * @param { Number } penalty - The value (sum) of the penalties set by the judge
   * @returns {Boolean} - true if the saving on server succeeded otherwise false
   */
  async saveCompulsoryNoteAsync(EVENT, staff_id, note, total, penalties, penalty, cartouche){
    //console.log('saveCompulsoryNote(EVENT, staff_id, note, total, penalties');

    // Pour commencer, on tente de récupérer les notes déjà envoyées au serveur.
    await this.refreshEventCompulsoriesNotesAsync(EVENT);

    var operationIds = [];
    //#region Préparation de l'enregistrement de la note du juge pour la figure et le compétiteur en cours
    var noteRecord = RST_COMPULSORY_NOTE.query()
                      .where("competition_id", EVENT.competition_id)
                      .where("round_number", EVENT.round_number)
                      .where("level", EVENT.level)
                      .where("category", EVENT.category)
                      .where("event", EVENT.event)
                      .where("competitor_id", EVENT.current_competitor_id)
                      .where("judge_id", staff_id)
                      .where("compulsory_id", EVENT.current_compulsory_id)
                      .first();
    var upd = {
      isAverage: (note == 'A'),
      noteEntry: (note == 'A') ? null : note,
      noteValue: (total == 'A') ? null: total,
      penalty: penalty,
      penalties: (penalties.count == 0) ? null : JSON.stringify(penalties),
      cartouche: JSON.stringify(cartouche),
    };
    //console.log('Save comulsory =>', upd);
    RST_COMPULSORY_NOTE.update({ where: noteRecord.id, data: upd});
    operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPULSORY_NOTE", noteRecord.id, upd, "NOTE"))
    //#endregion Préparation de l'enregistrement de la note du juge pour la figure et le compétiteur en cours

    //#region Gestion de l'ordre de passage "réel" du compétiteur
    if(EVENT.CURRENT_COMPETITOR.effective_order == null || EVENT.CURRENT_COMPETITOR.effective_order <= 0){
      var effectiveOrderOperationId = this.setCurrentCompetitorEffectiveOrder(EVENT);
      if(effectiveOrderOperationId != null)
        operationIds.push(effectiveOrderOperationId);
    }
    //#endregion Gestion de l'ordre de passage "réel" du compétiteur
    
    //#region Gestion des statuts "isStarted" au niveau épreuve, manche et compétition
    if(!noteRecord.EVENT.isStarted)
    {
      var evtUpd = { isStarted: true };
      RST_EVENT.update({ where: noteRecord.EVENT.id, data: evtUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT", noteRecord.EVENT.id, evtUpd, "EVENT_STARTS"));
    }
    if(!noteRecord.ROUND.isStarted)
    {
      var rndUpd = { isStarted: true};
      RST_ROUND.update({ where: noteRecord.ROUND.id, data: rndUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUND", noteRecord.ROUND.id, rndUpd, "IS_STARTED"));
    }
    if(!noteRecord.COMPETITION.isStarted)
    {
      var compUpd = { isStarted: true};
      RST_COMPETITION.update({ where: noteRecord.COMPETITION.id, data: compUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", noteRecord.COMPETITION.id, compUpd, "IS_STARTED"));      
    }
    //#endregion Gestion des statuts "isStarted" au niveau épreuve, manche et compétition


    //#region Gestion des classements pour cette figure par ce juge
    var allJudgeRecords = RST_COMPULSORY_NOTE.query()
      .where("competition_id", EVENT.competition_id)
      .where("round_number", EVENT.round_number)
      .where("level", EVENT.level)
      .where("category", EVENT.category)
      .where("event", EVENT.event)
      .where("judge_id", staff_id)
      .where("compulsory_id", EVENT.current_compulsory_id)
      .where(n => n.isCompleted && !n.isAverage)
      .get();
    var allJudgeFederalRecords = allJudgeRecords.filter(r => r.isFederal);
    allJudgeRecords.sort((a,b) => b.noteValue - a.noteValue);
    allJudgeRecords.forEach((n, index) => {
      var rkUpd = { rank: index + 1};
      RST_COMPULSORY_NOTE.update({ where: n.id, data: rkUpd});
    })
    allJudgeFederalRecords.sort((a,b) => b.noteValue - a.noteValue);
    allJudgeFederalRecords.forEach((n, index) => {
      var frkUpd = { federal_rank: index + 1};
      RST_COMPULSORY_NOTE.update({ where: n.id, data: frkUpd});
    })
    RST_COMPULSORY_NOTE.query()
      .where("competition_id", EVENT.competition_id)
      .where("round_number", EVENT.round_number)
      .where("level", EVENT.level)
      .where("category", EVENT.category)
      .where("event", EVENT.event)
      .where("judge_id", staff_id)
      .where("compulsory_id", EVENT.current_compulsory_id)
      .where(n => n.isCompleted && !n.isAverage)
      .get()
      .forEach(n => {
        var updRks = {
          rank: n.rank,
          federal_rank: n.federal_rank
        };
        operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPULSORY_NOTE", n.id, updRks, "RANKS"));
      });      
    //#endregion Gestion des classements pour cette figure par ce juge

    //#region Tentative de calcul de la note globale de cette figure pour le compétiteur en cours
    var totalCompulsoryOperationIds = this.computeCompulsoryTotal(EVENT);
    if(totalCompulsoryOperationIds.length > 0)
      operationIds = operationIds.concat(totalCompulsoryOperationIds);
    //#endregion Tentative de calcul de la note globale de cette figure pour le compétiteur en cours
     
    //#region Calcul, le cas échéant, si possible, de la note globale de ce juge pour l'ensemble des figures du compétiteur en cours
    var judgeNotes = RST_COMPULSORY_NOTE.query()
                      .where("competition_id", EVENT.competition_id)
                      .where("round_number", EVENT.round_number)
                      .where("level", EVENT.level)
                      .where("category", EVENT.category)
                      .where("event", EVENT.event)
                      .where("competitor_id", EVENT.current_competitor_id)
                      .where("judge_id", staff_id)
                      .where(n => n.compulsory_id != null)
                      .get();
    if(!judgeNotes.some(n => !n.isCompleted && !n.isAverage)){
      // Toutes les figures ont été notées par ce juge pour ce compétiteur. On peut donc déterminer le total pour ce compétiteur par ce juge
      var avg=0;
      var kept = judgeNotes.filter(n => !n.isAverage);
      if(kept.length > 0){
        var sum = kept.map(n => n.noteValue).reduce((c,n) => parseFloat(c) + parseFloat(n), 0);
        avg = sum / kept.length;
      }  
      // Maintenant que l'on a effectué le calcul, il faut retrouver l'enregistrement correspondant pour le mettre à jour.
      var judgeTotalRecord = RST_COMPULSORY_NOTE.query()
                      .where("competition_id", EVENT.competition_id)
                      .where("round_number", EVENT.round_number)
                      .where("level", EVENT.level)
                      .where("category", EVENT.category)
                      .where("event", EVENT.event)
                      .where("competitor_id", EVENT.current_competitor_id)
                      .where("judge_id", staff_id)
                      .where(n => n.compulsory_id == null)
                      .first();
      upd = {
        isAverage: false,
        noteEntry: avg,
        noteValue: avg,
        penalties: null,
      };
      RST_COMPULSORY_NOTE.update({ where: judgeTotalRecord.id, data: upd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPULSORY_NOTE", judgeTotalRecord.id, upd, "TOTAL"));

      //#region Gestion du classement des compétiteurs pour l'ensemble des figures notées par le juge en cours
      var allJudgeTotals = RST_COMPULSORY_NOTE.query()
                      .where("competition_id", EVENT.competition_id)
                      .where("round_number", EVENT.round_number)
                      .where("level", EVENT.level)
                      .where("category", EVENT.category)
                      .where("event", EVENT.event)
                      .where("judge_id", staff_id)
                      .where(n => n.compulsory_id == null)
                      .where(n => n.isCompleted && !n.isAverage)
                      .get();
      allJudgeTotals.sort((a,b) => b.note - a.note);
      allJudgeTotals.forEach((n, index) => {
        var rkUpd = { rank: index + 1};
        RST_COMPULSORY_NOTE.update({ where: n.id, data: rkUpd});
      })
      var allJudgeFederalTotals = allJudgeTotals.filter(r => r.isFederal);
      allJudgeFederalTotals.sort((a,b) => b.note - a.note);
      allJudgeFederalTotals.forEach((n, index) => {
        var frkUpd = { federal_rank: index + 1};
        RST_COMPULSORY_NOTE.update({ where: n.id, data: frkUpd});
      })
      RST_COMPULSORY_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("judge_id", staff_id)
        .where(n => n.compulsory_id == null)
        .where(n => n.isCompleted && !n.isAverage)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPULSORY_NOTE", n.id, updRks, "RANKS"));
        }); 
      //#endregion Gestion du classement des compétiteurs pour l'ensemble des figures notées par le juge en cours

      // Vu que l'on a été en mesure de calculer la note globale (toutes les figures) pour ce compétiteur par ce juge,
      // peut-être est-il possible de calculer la note finale de ce compétiteur pour les figures (tous les juges pour toutes les figures).
      var finalNoteOperationIds = this.computeFinalCompulsoriesNote(EVENT);
      if(finalNoteOperationIds.length > 0)
        operationIds = operationIds.concat(finalNoteOperationIds);
    }
    //#endregion Calcul, le cas échéant, si possible, de la note globale de ce juge pour l'ensemble des figures du compétiteur en cours

    return await SynchroHelper.TryToSynchronizeAsync(operationIds);
  },

  /**
   * Try to compute the total note for the current compulsory of the current competitor for the event (if all judge has given its note)
   * @param {RST_EVENT} EVENT - The event during while the compulsory is executed for the current competitor
   * @returns {String[]} - An array of the ids of the Log operations that have been created during the method execution
   */
  computeCompulsoryTotal(EVENT){
    //console.log('computeCompulsoryTotal(EVENT)');
    var operationIds = [];
    var allNotes = RST_COMPULSORY_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where(n => n.judge_id != null)
                    .where("compulsory_id", EVENT.current_compulsory_id)
                    .get();
    if(allNotes.some(n => !(n.isCompleted || n.isAverage)))
      return operationIds;
    
    //console.log('A IMPLEMENTER => oter la moins bonne et la meilleure note pour ce calcul (computeCompulsory)');
    var kept = allNotes.filter(n => !n.isAverage && !n.isShadowNote);
    if(kept.length > 0){
      var sum = kept.map(n => n.noteValue).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var penaltySum = kept.map(n => n.penalty).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var total = sum / kept.length;
      var penaltyTotal = penaltySum / kept.length;
//      var total = grossTotal - penaltyTotal;

      var noteTotal = RST_COMPULSORY_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("competitor_id", EVENT.current_competitor_id)
        .where(n => n.judge_id == null)
        .where("compulsory_id", EVENT.current_compulsory_id)
        .first();

      var upd = { noteValue: total, penalty: penaltyTotal };
      RST_COMPULSORY_NOTE.update({ where: noteTotal.id, data: upd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPULSORY_NOTE", noteTotal.id, upd, "TOTAL"));
      //console.log('computeCompulsoryTotal => ', upd);
      // Puisque la note globale de cette figure a pu être calculée pour le compétiteur en cours, il faut maintenant gérer le classement correspondant à cette note.
      //#region Gestion des classements pour la figure en cours
      var allRecords = RST_COMPULSORY_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where(n => n.judge_id == null)
        .where("compulsory_id", EVENT.current_compulsory_id)
        .where(n => n.isCompleted && !n.isAverage)
        .get();

      allRecords.sort((a,b) => b.noteValue - a.noteValue);
      var prevRecord = { value:0, rank : 0};
      allRecords.forEach((n, index) => {
        var r = n.noteValue == prevRecord.value ? prevRecord.rank : index +1;
        var rkUpd = { rank: r};
        RST_COMPULSORY_NOTE.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.noteValue, rank: r};
      })

      var allFederalRecords = allRecords.filter(r => r.isFederal);
      allFederalRecords.sort((a,b) => b.noteValue - a.noteValue);
      prevRecord = { value:0, rank : 0};
      allFederalRecords.forEach((n, index) => {
        var r = n.noteValue == prevRecord.value ? prevRecord.rank : index +1;
        var frkUpd = { federal_rank: r};
        RST_COMPULSORY_NOTE.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.noteValue, rank: r};
      })

      RST_COMPULSORY_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where(n => n.judge_id == null)
        .where("compulsory_id", EVENT.current_compulsory_id)
        .where(n => n.isCompleted && !n.isAverage)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPULSORY_NOTE", n.id, updRks, "RANKS"));
        });      
      //#endregion Gestion des classements pour la figure en cours
    }
    
    return operationIds;
  },

  /**
   * Try to compute the total note for the current compulsory of the current competitor for the event (if all judge has givent its note)
   * and save it if necessary
   * @param {RST_EVENT} EVENT - The event during while the compulsory is executed for the current competitor
   * @returns {Boolean} - true if the saving on server succeeded otherwise false
   */
  async computeAndSaveCompulsoryTotalAsync(EVENT){
    //console.log('computeAndSaveCompulsoryTotalAsync(EVENT)', EVENT);
    await this.refreshEventCompulsoriesNotesAsync(EVENT);
    var operationIds = this.computeCompulsoryTotal(EVENT);
    if(operationIds.length > 0)
      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    return false;
  },

  /**
   * Try to compute the final total note for all the compulsories of the current competitor for the event (if all judge has given their notes)
   * @param {RST_EVENT} EVENT - The event during while the compulsories are executed for the current competitor
   * @returns {String[]} - An array of the ids of the Log operations that have been created during the method execution
   */
  computeFinalCompulsoriesNote(EVENT)
  {
    console.log('computeFinalCompulsoriesNote(EVENT)');
    var operationIds = [];
    var allNotes = RST_COMPULSORY_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where(n => n.judge_id == null)
                    .where(n => n.compulsory_id != null)
                    .get();
    if(allNotes.some(n => !(n.isCompleted || n.isAverage)))
      return operationIds;
    
    var kept = allNotes.filter(n => !n.isAverage);
    if(kept.length > 0){
      var sum = kept.map(n => n.noteValue).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var total = sum / kept.length;

      var noteTotal = RST_COMPULSORY_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("competitor_id", EVENT.current_competitor_id)
        .where(n => n.judge_id == null)
        .where(n => n.compulsory_id == null)
        .first();

      var upd = { noteValue: total };
      RST_COMPULSORY_NOTE.update({ where: noteTotal.id, data: upd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPULSORY_NOTE", noteTotal.id, upd, "TOTAL"));

      // Puisque la note globale des figures a pu être calculée pour le compétiteur en cours, il faut maintenant gérer le classement correspondant à cette note.
      //#region Gestion des classements pour la finale des figures
      var allRecords = RST_COMPULSORY_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where(n => n.judge_id == null)
        .where(n => n.compulsory_id == null)
        .where(n => n.isCompleted && !n.isAverage)
        .get();

      allRecords.sort((a,b) => b.noteValue - a.noteValue);
      var prevRecord = { value: 0, rank: 0};
      allRecords.forEach((n, index) => {
        var r = n.noteValue == prevRecord.value ? prevRecord.rank : index +1;
        var rkUpd = { rank: r};
        RST_COMPULSORY_NOTE.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.noteValue, rank: r};
      })

      var allFederalRecords = allRecords.filter(r => r.isFederal);
      allFederalRecords.sort((a,b) => b.noteValue - a.noteValue);
      prevRecord = { value: 0, rank: 0}
      allFederalRecords.forEach((n, index) => {
        var r = n.noteValue == prevRecord.value ? prevRecord.rank : index +1;
        var frkUpd = { federal_rank: r};
        RST_COMPULSORY_NOTE.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.noteValue, rank: r};
      })

      RST_COMPULSORY_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where(n => n.judge_id == null)
        .where(n => n.compulsory_id == null)
        .where(n => n.isCompleted && !n.isAverage)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPULSORY_NOTE", n.id, updRks, "RANKS"));
        });      
      //#endregion Gestion des classements pour la finale des figures

      // Puisque la note globale des figures a pu être calculée pour le compétiteur en cours, on peut essayer de calculer sa note finale pour l'épreuve
      var finalEventNoteOperationIds = this.computeFinalEventNote(EVENT);
      if(finalEventNoteOperationIds.length > 0)
        operationIds = operationIds.concat(finalEventNoteOperationIds);
    }
    
    return operationIds;    
  },

  /**
   * Try to compute the final total note for all the compulsories of the current competitor for the event (if all judge has given their notes)
   * and save it if necessary
   * @param {RST_EVENT} EVENT - The event during while the compulsories are executed for the current competitor
   * @returns {Boolean} - true if the saving on server succeeded otherwise false
   */
  async computeAndSaveFinalCompulsoriesNoteAsync(EVENT){
    console.log('computeAndSaveFinalCompulsoriesNoteAsync(EVENT)', EVENT);
    await this.refreshEventCompulsoriesNotesAsync(EVENT);
    var operationIds = this.computeFinalCompulsoriesNote(EVENT);
    if(operationIds.length > 0)
    return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    return false;
  },

//#endregion Compulsory Note entry and computation (savings)

//#region Routine Notes entry and computation (savings)

  /** Raffraichit les notes de routine entrées ou calculées dans le cadre de l'épreuve indiquée en paramètre
   * @param {RST_EVENT} EVENT 
   */
   async refreshEventRoutinesNotesAsync(EVENT)
   {
     // Pour ne pas risquer d'écraser des notes saisies sur l'appareil courrant et pas encore reporétées sur le serveur,
     // on ne s'autorise le raffraichissement qu'à la condition qu'il n'y ait pas de log en cours pour cet objet.
     // Le premier test vérifie si il existe des logs
     // Si c'est le cas, on tente de les synchroniser avant de tester de nouveau l'existence de logs.
     // Si il y a toujours des logs après cela, on ne fait tente pas de mise à jour des données.
     if(RST_LOG.query().where('operation', 'UPDATE').where('object_type', 'RST_ROUTINE_NOTE').exists()){
       await SynchroHelper.TryToSynchronizeAsync();
       if(RST_LOG.query().where('operation', 'UPDATE').where('object_type', 'RST_ROUTINE_NOTE').exists())
         return false;
     }
     var baseUrl = store.getters["config/serverBaseUrl"];
     var url = baseUrl + '/api/competitions/getRoutinesNoteValues.php';
     
     try{
       const response = await axios.post(url, { 
         competition_id: EVENT.competition_id, 
         round_number: EVENT.round_number,
         level: EVENT.level,
         category: EVENT.category,
         event: EVENT.event,
       });
       if(response.data && response.data.routineNotes && response.data.routineNotes.length > 0){
         RST_ROUTINE_NOTE.update({data: response.data.routineNotes});
         return true;
       }
       return false;
     } catch(error){
       console.log('refreshEventRoutinesNotes ERROR ', error);
       return false;
     }
   },

  /** Save the notes set by a judge for a Routine executed by a competitor
   * @param {RST_EVENT} EVENT - The event during while the compulsory is executed
   * @param {String} staff_id - Id of the judge
   * @param {*} execution - Note given by the judge for the "execution" component of the routine
   * @param {*} content - Note given by the judge for the "content" component of the routine
   * @param {*} total - Final note resulting of the two others, includint the potential points substractions due to penalties
   * @param { Object } penalties - An object containing the details of the penalties set.
   * @param { Number } grossTotal - The total excluding the value of the potential penalties
   * @param { Number } penalty - The value (sum) of the penalties set by the judge
   * @param { Object } cartouche - An object containing the details of the evaluation of the judge
   * @param { Number } executionPound - The pound (percent) of the execution note in the total
   * @param { Number } contentPound - The pound (percent) of the execution note in the total
   * @returns {Boolean} - true if the saving on server succeeded otherwise false
    */
  async saveRoutineNoteAsync(EVENT, staff_id, execution, content, total, penalties, grossTotal, grossExecution, grossContent, penalty, cartouche, executionPound = 0.6, contentPound = 0.4) {
    console.log('saveRoutineNote(EVENT, staff_id, note, total, penalties');

    // Pour commencer, on tente de récupérer les notes déjà envoyées au serveur.
    await this.refreshEventRoutinesNotesAsync(EVENT);

    var operationIds = [];
    //#region Préparation de l'enregistrement de la note du juge pour la routine du compétiteur en cours
    var noteRecord = RST_ROUTINE_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where("judge_id", staff_id)
                    .first();
    var upd = {
      execution: execution,
      content: content,
      note: total,
      penalty: penalty,
      penalties: (penalties.count == 0) ? null : JSON.stringify(penalties),
      grossTotal: grossTotal,
      grossExecution: grossExecution,
      grossContent: grossContent,
      cartouche: JSON.stringify(cartouche),
    };
    RST_ROUTINE_NOTE.update({ where: noteRecord.id, data: upd});
    operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUTINE_NOTE", noteRecord.id, upd, "NOTE"))
    //#endregion Préparation de l'enregistrement de la note du juge pour la routine du compétiteur en cours

    //#region Gestion de l'ordre de passage "réel" du compétiteur
    if(EVENT.CURRENT_COMPETITOR.effective_order == null || EVENT.CURRENT_COMPETITOR.effective_order <= 0){
      var effectiveOrderOperationId = this.setCurrentCompetitorEffectiveOrder(EVENT);
      if(effectiveOrderOperationId != null)
        operationIds.push(effectiveOrderOperationId);
    }
    //#endregion Gestion de l'ordre de passage "réel" du compétiteur

    //#region Gestion des statuts "isStarted" au niveau épreuve, manche et compétition
    if(!noteRecord.EVENT.isStarted)
    {
      var evtUpd = { isStarted: true };
      RST_EVENT.update({ where: noteRecord.EVENT.id, data: evtUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT", noteRecord.EVENT.id, evtUpd, "EVENT_STARTS"));
    }
    if(!noteRecord.ROUND.isStarted)
    {
      var rndUpd = { isStarted: true};
      RST_ROUND.update({ where: noteRecord.ROUND.id, data: rndUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUND", noteRecord.ROUND.id, rndUpd, "IS_STARTED"));
    }
    if(!noteRecord.COMPETITION.isStarted)
    {
      var compUpd = { isStarted: true};
      RST_COMPETITION.update({ where: noteRecord.COMPETITION.id, data: compUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", noteRecord.COMPETITION.id, compUpd, "IS_STARTED"));      
    }
    //#endregion Gestion des statuts "isStarted" au niveau épreuve, manche et compétition

    //#region Gestion des classements pour cette routine par ce juge
    var allJudgeRecords = RST_ROUTINE_NOTE.query()
      .where("competition_id", EVENT.competition_id)
      .where("round_number", EVENT.round_number)
      .where("level", EVENT.level)
      .where("category", EVENT.category)
      .where("event", EVENT.event)
      .where("judge_id", staff_id)
      .where(n => n.isCompleted)
      .get();    
      var allJudgeFederalRecords = allJudgeRecords.filter(r => r.isFederal);
      allJudgeRecords.sort((a,b) => b.execution - a.execution);
      allJudgeRecords.forEach((n, index) => {
        var rkUpd = { execution_rank: index + 1};
        RST_ROUTINE_NOTE.update({ where: n.id, data: rkUpd});
      })
      allJudgeFederalRecords.sort((a,b) => b.execution - a.execution);
      allJudgeFederalRecords.forEach((n, index) => {
        var frkUpd = { execution_federal_rank: index + 1};
        RST_ROUTINE_NOTE.update({ where: n.id, data: frkUpd});
      })
      allJudgeRecords.sort((a,b) => b.content - a.content);
      allJudgeRecords.forEach((n, index) => {
        var rkUpd = { content_rank: index + 1};
        RST_ROUTINE_NOTE.update({ where: n.id, data: rkUpd});
      })  
      allJudgeFederalRecords.sort((a,b) => b.content - a.content);
      allJudgeFederalRecords.forEach((n, index) => {
        var frkUpd = { content_federal_rank: index + 1};
        RST_ROUTINE_NOTE.update({ where: n.id, data: frkUpd});
      })
      allJudgeRecords.sort((a,b) => b.note - a.note);
      allJudgeRecords.forEach((n, index) => {
        var rkUpd = { rank: index + 1};
        RST_ROUTINE_NOTE.update({ where: n.id, data: rkUpd});
      })
      allJudgeFederalRecords.sort((a,b) => b.note - a.note);
      allJudgeFederalRecords.forEach((n, index) => {
        var frkUpd = { federal_rank: index + 1};
        RST_ROUTINE_NOTE.update({ where: n.id, data: frkUpd});
      })

      RST_ROUTINE_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("judge_id", staff_id)
        .where(n => n.isCompleted)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank,
            execution_rank: n.execution_rank,
            execution_federal_rank: n.execution_federal_rank,
            content_rank: n.content_rank,
            content_federal_rank: n.content_federal_rank,
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUTINE_NOTE", n.id, updRks, "RANKS"));
        });     
      //#endregion Gestion des classements pour cette routine par ce juge

    // Est-il possible de calculer la note finale du compétiteur en cours pour sa routine ?
    var finalNoteOperationIds = this.computeFinalRoutineNotes(EVENT, executionPound, contentPound);
    if(finalNoteOperationIds.length > 0)
        operationIds = operationIds.concat(finalNoteOperationIds);

    return await SynchroHelper.TryToSynchronizeAsync(operationIds);
  },
  /** Try to compute the final total note for the routine of the current competitor for the event (if all judge has given their notes)
   * @param {RST_EVENT} EVENT - The event during while the routine is executed for the current competitor
   * @param { Number } executionPound - The pound (percent) of the execution note in the total
   * @param { Number } contentPound - The pound (percent) of the execution note in the total
   * @returns {String[]} - An array of the ids of the Log operations that have been created during the method execution
   */
  computeFinalRoutineNotes(EVENT/*, executionPound = 0.6, contentPound = 0.4*/){
    console.log('computeFinalRoutineNotes(EVENT)');
    var operationIds = [];
    var allNotes = RST_ROUTINE_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where(n => n.judge_id != null)
                    .get();
    if(allNotes.some(n => !(n.isCompleted)))
      return operationIds;
    
    var kept = allNotes.filter(n => !n.isShadowNote); //.filter(n => true);
    if(kept.length > 0){
      var noteSum = kept.map(n => n.note).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var executionSum = kept.map(n => n.execution).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var contentSum = kept.map(n => n.content).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var penaltySum = kept.map(n => n.penalty).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var grossTotalSum = kept.map(n => n.grossTotal).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var grossExecutionSum = kept.map(n => n.grossExecution).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var grossContentSum = kept.map(n => n.grossContent).reduce((p,c) => parseFloat(p) + parseFloat(c));

      var total = noteSum / kept.length;
      var totalExecution = executionSum / kept.length;
      var totalContent = contentSum / kept.length;
      var totalPenalty = penaltySum / kept.length;
      //var grossTotal = (totalExecution * executionPound) + (totalContent * contentPound);
      //var total = grossTotal - totalPenalty;
      var grossTotal = grossTotalSum / kept.length;
      var grossExecution = grossExecutionSum / kept.length;
      var grossContent = grossContentSum / kept.length;

      var noteTotal = RST_ROUTINE_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("competitor_id", EVENT.current_competitor_id)
        .where(n => n.judge_id == null)
        .first();

      var upd = { note: total, execution: totalExecution, content: totalContent, grossTotal: grossTotal, grossExecution: grossExecution, grossContent: grossContent, penalty: totalPenalty };
      RST_ROUTINE_NOTE.update({ where: noteTotal.id, data: upd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUTINE_NOTE", noteTotal.id, upd, "NOTE"));

      // Puisque la note finale de la routine a pu être calculée pour le compétiteur en cours, il faut maintenant gérer le classement correspondant à cette note.
      //#region Gestion des classements pour la note finale de routine du compétiteur
      var allRecords = RST_ROUTINE_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where(n => n.judge_id == null)
        .where(n => n.isCompleted)
        .get();
      allRecords.sort((a,b) => b.note - a.note);
      var prevRecord = { value: 0, rank: 0};
      allRecords.forEach((n, index) => {
        var r = n.note == prevRecord.value ? prevRecord.rank : index + 1;
        var rkUpd = { rank: index + 1};
        RST_ROUTINE_NOTE.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.note, rank: r};
      })

      allRecords.sort((a,b) => b.execution - a.execution);
      prevRecord = { value: 0, rank: 0};
      allRecords.forEach((n, index) => {
        var r = n.execution == prevRecord.value ? prevRecord.rank : index + 1;
        var rkUpd = { execution_rank: r};
        RST_ROUTINE_NOTE.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.execution, rank: r};
      })

      allRecords.sort((a,b) => b.content - a.content);
      prevRecord = { value: 0, rank: 0};
      allRecords.forEach((n, index) => {
        var r = n.content == prevRecord.value ? prevRecord.rank : index + 1;
        var rkUpd = { content_rank: r};
        RST_ROUTINE_NOTE.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.content, rank: r};
      })

      var allFederalRecords = allRecords.filter(r => r.isFederal);
      allFederalRecords.sort((a,b) => b.note - a.note);
      prevRecord = { value: 0, rank: 0};
      allFederalRecords.forEach((n, index) => {
        var r = n.note == prevRecord.value ? prevRecord.rank : index + 1;
        var frkUpd = { federal_rank: r};
        RST_ROUTINE_NOTE.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.note, rank: r};
      })

      allFederalRecords.sort((a,b) => b.execution - a.execution);
      prevRecord = { value: 0, rank: 0};
      allFederalRecords.forEach((n, index) => {
        var r = n.execution == prevRecord.value ? prevRecord.rank : index + 1;
        var frkUpd = { execution_federal_rank: r};
        RST_ROUTINE_NOTE.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.execution, rank: r};
      })

      allFederalRecords.sort((a,b) => b.content - a.content);
      prevRecord = { value: 0, rank: 0};
      allFederalRecords.forEach((n, index) => {
        var r = n.content == prevRecord.value ? prevRecord.rank : index + 1;
        var frkUpd = { content_federal_rank: r};
        RST_ROUTINE_NOTE.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.content, rank: r};
      })

      RST_ROUTINE_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where(n => n.judge_id == null)
        .where(n => n.isCompleted)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank,
            execution_rank: n.execution_rank,
            execution_federal_rank: n.execution_federal_rank,
            content_rank: n.content_rank,
            content_federal_rank: n.content_federal_rank,
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUTINE_NOTE", n.id, updRks, "RANKS"));
        });      
      //#endregion Gestion des classements pour la note finale de routine du compétiteur

      // Puisque la note finale de routine a pu être calculée, peut-être est-il possible de calculer la note finale du compétiteur pour l'épreuve
      var finalEventNoteOperationIds = this.computeFinalEventNote(EVENT);
      if(finalEventNoteOperationIds.length > 0)
        operationIds = operationIds.concat(finalEventNoteOperationIds);
    }
    
    return operationIds; 
  },

  /** Try to compute the final total note for the routine of the current competitor for the event (if all judge has given their notes)
   * and save it if necessary
   * @param {RST_EVENT} EVENT - The event during while the compulsories are executed for the current competitor
   * @param { Number } executionPound - The pound (percent) of the execution note in the total
   * @param { Number } contentPound - The pound (percent) of the execution note in the total
   * @returns {Boolean} - true if the saving on server succeeded otherwise false
   */
  async computeAndSaveFinalRoutineNotesAsync(EVENT, executionPound = 0.6, contentPound = 0.4)
  {
    console.log('computeAndSaveFinalRoutineNotesAsync(EVENT)', EVENT);
    await this.refreshEventRoutinesNotesAsync(EVENT);
    var operationIds = this.computeFinalRoutineNotes(EVENT, executionPound, contentPound);
    if(operationIds.length > 0)
    return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    return false;
  },
//#endregion Routine Notes entry and computation (savings)

//#region Ballet Notes entry and computation (savings)

  /** Raffraichit les notes entrées ou calculées dans le cadre de l'épreuve indiquée en paramètre
   * @param {RST_EVENT} EVENT 
   */
   async refreshEventBalletsNotesAsync(EVENT)
   {
     // Pour ne pas risquer d'écraser des notes saisies sur l'appareil courrant et pas encore reporétées sur le serveur,
     // on ne s'autorise le raffraichissement qu'à la condition qu'il n'y ait pas de log en cours pour cet objet.
     // Le premier test vérifie si il existe des logs
     // Si c'est le cas, on tente de les synchroniser avant de tester de nouveau l'existence de logs.
     // Si il y a toujours des logs après cela, on ne fait tente pas de mise à jour des données.
     if(RST_LOG.query().where('operation', 'UPDATE').where('object_type', 'RST_BALLET_NOTE').exists()){
       await SynchroHelper.TryToSynchronizeAsync();
       if(RST_LOG.query().where('operation', 'UPDATE').where('object_type', 'RST_BALLET_NOTE').exists())
         return false;
     }
     var baseUrl = store.getters["config/serverBaseUrl"];
     var url = baseUrl + '/api/competitions/getBalletsNoteValues.php';
     
     try{
       const response = await axios.post(url, { 
         competition_id: EVENT.competition_id, 
         round_number: EVENT.round_number,
         level: EVENT.level,
         category: EVENT.category,
         event: EVENT.event,
       });
       // console.log('RefreshBalletNotes', response.data);
       if(response.data && response.data.balletNotes && response.data.balletNotes.length > 0){
         RST_BALLET_NOTE.update({data: response.data.balletNotes});
         return true;
       }
       return false;
     } catch(error){
       console.log('refreshEventBalletsNotes ERROR ', error);
       return false;
     }
   },

  /** Save the notes set by a judge for a ballet executed by a competitor
   * @param {RST_EVENT} EVENT - The event during while the compulsory is executed
   * @param {String} staff_id - Id of the judge
   * @param {*} choreo - Note given by the judge for the "choregraphy" component of the routine
   * @param {*} execution - Note given by the judge for the "execution" component of the routine
   * @param {*} total - Final note resulting of the two others, includint the potential points substractions due to penalties
   * @param { Object } penalties - An object containing the details of the penalties set.
   * @param { Number } grossTotal - The total excluding the value of the potential penalties
   * @param { Number } penalty - The value (sum) of the penalties set by the judge
   * @param { Object } cartouche - An object containing the details of the evaluation of the judge
   * @param { Number } choreoPound - The pound (percent) of the choregraphy note in the total
   * @param { Number } executionPound - The pound (percent) of the execution note in the total
  * @returns {Boolean} - true if the saving on server succeeded otherwise false
    */
  async saveBalletNoteAsync(EVENT, staff_id, choreo, execution, total, penalties, grossChoreo, grossExecution, grossTotal, penalty, cartouche, choreoPound = 0.6, executionPound = 0.4) {

    // Pour commencer, on tente de récupérer les notes déjà envoyées au serveur.
    await this.refreshEventBalletsNotesAsync(EVENT);

    var operationIds = [];
    //#region Préparation de l'enregistrement de la note du juge pour le ballet du compétiteur en cours
    var noteRecord = RST_BALLET_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where("judge_id", staff_id)
                    .first();
    var upd = {
      execution: execution,
      grossExecution: grossExecution ,
      choreo: choreo,
      grossChoreo: grossChoreo,
      note: total,
      penalty: penalty,
      penalties: (penalties.count == 0) ? null : JSON.stringify(penalties),
      grossTotal: grossTotal,
      cartouche: JSON.stringify(cartouche),
    };
    RST_BALLET_NOTE.update({ where: noteRecord.id, data: upd});
    operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_BALLET_NOTE", noteRecord.id, upd, "NOTE"))
    //#endregion Préparation de l'enregistrement de la note du juge pour le du compétiteur en cours

    //#region Gestion de l'ordre de passage "réel" du compétiteur
    if(EVENT.CURRENT_COMPETITOR.effective_order == null || EVENT.CURRENT_COMPETITOR.effective_order <= 0){
      var effectiveOrderOperationId = this.setCurrentCompetitorEffectiveOrder(EVENT);
      if(effectiveOrderOperationId != null)
        operationIds.push(effectiveOrderOperationId);
    }
    //#endregion Gestion de l'ordre de passage "réel" du compétiteur
  
    //#region Gestion des statuts "isStarted" au niveau épreuve, manche et compétition
    if(!noteRecord.EVENT.isStarted)
    {
      var evtUpd = { isStarted: true };
      RST_EVENT.update({ where: noteRecord.EVENT.id, data: evtUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT", noteRecord.EVENT.id, evtUpd, "EVENT_STARTS"));
    }
    if(!noteRecord.ROUND.isStarted)
    {
      var rndUpd = { isStarted: true};
      RST_ROUND.update({ where: noteRecord.ROUND.id, data: rndUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUND", noteRecord.ROUND.id, rndUpd, "IS_STARTED"));
    }
    if(!noteRecord.COMPETITION.isStarted)
    {
      var compUpd = { isStarted: true};
      RST_COMPETITION.update({ where: noteRecord.COMPETITION.id, data: compUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", noteRecord.COMPETITION.id, compUpd, "IS_STARTED"));      
    }
    //#endregion Gestion des statuts "isStarted" au niveau épreuve, manche et compétition

    //#region Gestion des classements pour ce ballet par ce juge
    var allJudgeRecords = RST_BALLET_NOTE.query()
      .where("competition_id", EVENT.competition_id)
      .where("round_number", EVENT.round_number)
      .where("level", EVENT.level)
      .where("category", EVENT.category)
      .where("event", EVENT.event)
      .where("judge_id", staff_id)
      .where(n => n.isCompleted)
      .get();    
      var allJudgeFederalRecords = allJudgeRecords.filter(r => r.isFederal);
      allJudgeRecords.sort((a,b) => b.execution - a.execution);
      allJudgeRecords.forEach((n, index) => {
        var rkUpd = { execution_rank: index + 1};
        RST_BALLET_NOTE.update({ where: n.id, data: rkUpd});
      })
      allJudgeFederalRecords.sort((a,b) => b.execution - a.execution);
      allJudgeFederalRecords.forEach((n, index) => {
        var frkUpd = { execution_federal_rank: index + 1};
        RST_BALLET_NOTE.update({ where: n.id, data: frkUpd});
      })
      allJudgeRecords.sort((a,b) => b.choreo - a.choreo);
      allJudgeRecords.forEach((n, index) => {
        var rkUpd = { choreo_rank: index + 1};
        RST_BALLET_NOTE.update({ where: n.id, data: rkUpd});
      })  
      allJudgeFederalRecords.sort((a,b) => b.choreo - a.choreo);
      allJudgeFederalRecords.forEach((n, index) => {
        var frkUpd = { choreo_federal_rank: index + 1};
        RST_BALLET_NOTE.update({ where: n.id, data: frkUpd});
      })
      allJudgeRecords.sort((a,b) => b.note - a.note);
      allJudgeRecords.forEach((n, index) => {
        var rkUpd = { rank: index + 1};
        RST_BALLET_NOTE.update({ where: n.id, data: rkUpd});
      })
      allJudgeFederalRecords.sort((a,b) => b.note - a.note);
      allJudgeFederalRecords.forEach((n, index) => {
        var frkUpd = { federal_rank: index + 1};
        RST_BALLET_NOTE.update({ where: n.id, data: frkUpd});
      })

      RST_BALLET_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("judge_id", staff_id)
        .where(n => n.isCompleted)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank,
            execution_rank: n.execution_rank,
            execution_federal_rank: n.execution_federal_rank,
            choreo_rank: n.choreo_rank,
            choreo_federal_rank: n.choreo_federal_rank,
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_BALLET_NOTE", n.id, updRks, "RANKS"));
        });     
      //#endregion Gestion des classements pour ce ballet par ce juge

    // Est-il possible de calculer la note finale du compétiteur en cours pour son ballet ?
    var finalNoteOperationIds = this.computeFinalBalletNotes(EVENT, choreoPound, executionPound);
    if(finalNoteOperationIds.length > 0)
        operationIds = operationIds.concat(finalNoteOperationIds);

    return await SynchroHelper.TryToSynchronizeAsync(operationIds);
  },
  /** Try to compute the final total note for the ballet of the current competitor for the event (if all judge has given their notes)
   * @param {RST_EVENT} EVENT - The event during while the routine is executed for the current competitor
   * @param { Number } executionPound - The pound (percent) of the execution note in the total
   * @param { Number } contentPound - The pound (percent) of the execution note in the total
   * @returns {String[]} - An array of the ids of the Log operations that have been created during the method execution
   */
  computeFinalBalletNotes(EVENT/*, choreoPound = 0.6, executionPound = 0.4*/){

    var operationIds = [];
    var allNotes = RST_BALLET_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where(n => n.judge_id != null)
                    .get();
                    //console.log('allNotes', allNotes);
    if(allNotes.some(n => !(n.isCompleted)))
      return operationIds;
    
    var kept = allNotes.filter(n => !n.isShadowNote ); //.filter(n => true);
    if(kept.length > 0){
      var noteSum = kept.map(n => n.note).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var executionSum = kept.map(n => n.execution).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var choreoSum = kept.map(n => n.choreo).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var penaltySum = kept.map(n => n.penalty).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var grossTotalSum = kept.map(n => n.grossTotal).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var grossExecutionSum = kept.map(n => n.grossExecution).reduce((p,c) => parseFloat(p) + parseFloat(c));
      var grossChoreoSum = kept.map(n => n.grossChoreo).reduce((p,c) => parseFloat(p) + parseFloat(c));

      var total = noteSum / kept.length;
      var totalExecution = executionSum / kept.length;
      var totalChoreo = choreoSum / kept.length;
      var totalPenalty = penaltySum / kept.length;
      //var grossTotal = (totalExecution * executionPound) + (totalChoreo * choreoPound);
      var grossTotal = grossTotalSum / kept.length;
      var grossExecution = grossExecutionSum / kept.length;
      var grossChoreo = grossChoreoSum / kept.length;
      //var total = grossTotal - totalPenalty;  //kept.map(n => n.total).reduce((p,c) => parseFloat(p) + parseFloat(c)) / kept.length;

      var noteTotal = RST_BALLET_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("competitor_id", EVENT.current_competitor_id)
        .where(n => n.judge_id == null)
        .first();

      var upd = { note: total, execution: totalExecution, choreo: totalChoreo, grossTotal: grossTotal, grossExecution: grossExecution, grossChoreo: grossChoreo, penalty: totalPenalty };
      RST_BALLET_NOTE.update({ where: noteTotal.id, data: upd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_BALLET_NOTE", noteTotal.id, upd, "NOTE"));

      // Puisque la note finale du ballet a pu être calculée pour le compétiteur en cours, il faut maintenant gérer le classement correspondant à cette note.
      //#region Gestion des classements pour la note finale de ballet du compétiteur
      var allRecords = RST_BALLET_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where(n => n.judge_id == null)
        .where(n => n.isCompleted)
        .get();

      allRecords.sort((a,b) => b.note - a.note);
      var prevRecord = { value: 0, rank: 0};
      allRecords.forEach((n, index) => {
        var r = (n.note == prevRecord.value) ? prevRecord.rank : index + 1;
        var rkUpd = { rank: r};
        RST_BALLET_NOTE.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.note, rank: r};
      })

      allRecords.sort((a,b) => b.execution - a.execution);
      prevRecord = { value: 0, rank: 0};
      allRecords.forEach((n, index) => {
        var r = (n.execution == prevRecord.value) ? prevRecord.rank : index + 1;
        var rkUpd = { execution_rank: r};
        RST_BALLET_NOTE.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.execution, rank: r};
      })

      allRecords.sort((a,b) => b.choreo - a.choreo);
      prevRecord = { value: 0, rank: 0};
      allRecords.forEach((n, index) => {
        var r = (n.choreo == prevRecord.value) ? prevRecord.rank : index + 1;
        var rkUpd = { choreo_rank: r};
        RST_BALLET_NOTE.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.choreo, rank: r};
      })
      var allFederalRecords = allRecords.filter(r => r.isFederal);

      allFederalRecords.sort((a,b) => b.note - a.note);
      prevRecord = { value: 0, rank: 0};
      allFederalRecords.forEach((n, index) => {
        var r = (n.note == prevRecord.value) ? prevRecord.rank : index + 1;
         var frkUpd = { federal_rank: r};
        RST_BALLET_NOTE.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.note, rank: r};
      })

      allFederalRecords.sort((a,b) => b.execution - a.execution);
      prevRecord = { value: 0, rank: 0};
      allFederalRecords.forEach((n, index) => {
        var r = (n.execution == prevRecord.value) ? prevRecord.rank : index + 1;
         var frkUpd = { execution_federal_rank: r};
        RST_BALLET_NOTE.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.execution, rank: r};
      })

      allFederalRecords.sort((a,b) => b.choreo - a.choreo);
      prevRecord = { value: 0, rank: 0};
      allFederalRecords.forEach((n, index) => {
        var r = (n.choreo == prevRecord.value) ? prevRecord.rank : index + 1;
         var frkUpd = { choreo_federal_rank: r};
        RST_BALLET_NOTE.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.choreo, rank: r};
      })

      RST_BALLET_NOTE.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where(n => n.judge_id == null)
        .where(n => n.isCompleted)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank,
            execution_rank: n.execution_rank,
            execution_federal_rank: n.execution_federal_rank,
            choreo_rank: n.choreo_rank,
            choreo_federal_rank: n.choreo_federal_rank,
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_BALLET_NOTE", n.id, updRks, "RANKS"));
        });      
      //#endregion Gestion des classements pour la note finale de ballet du compétiteur

      // Puisque la note finale de ballet a pu être calculée, peut-être est-il possible de calculer la note finale du compétiteur pour l'épreuve
      var finalEventNoteOperationIds = this.computeFinalEventNote(EVENT);
      if(finalEventNoteOperationIds.length > 0)
        operationIds = operationIds.concat(finalEventNoteOperationIds);
    }
    
    return operationIds; 
  },

  /**
   * Try to compute the final total note for the ballet of the current competitor for the event (if all judge has given their notes)
   * and save it if necessary
   * @param {RST_EVENT} EVENT - The event during while the compulsories are executed for the current competitor
   * @param { Number } executionPound - The pound (percent) of the execution note in the total
   * @param { Number } contentPound - The pound (percent) of the execution note in the total
   * @returns {Boolean} - true if the saving on server succeeded otherwise false
   */
  async computeAndSaveFinalBalletNotesAsync(EVENT, choreoPound = 0.6, executionPound = 0.4)
  {
    //console.log('computeAndSaveFinalBAlletNotesAsync(EVENT)', EVENT);
    await this.refreshEventBalletsNotesAsync(EVENT);
    var operationIds = this.computeFinalBalletNotes(EVENT, choreoPound, executionPound);
    if(operationIds.length > 0)
    return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    return false;
  },

//#endregion Ballet Notes entry and computation (savings)

//#region EVENT Notes computation (savings)

  /**
   * Try to compute the finale "Event" note for the current competitor
   * @param {RST_EVENT} EVENT 
   */
  computeFinalEventNote(EVENT){
    console.log('computeFinalEventNote(EVENT)', EVENT);
    var operationIds = [];
    var note = 0;
    var precision = null;
    var ballet = null;
    if(EVENT.EVENT.hasCompulsories)
    {
      var compulsoriesNote = RST_COMPULSORY_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where(n => n.judge_id == null)
                    .where(n => n.compulsory_id == null)
                    .first();
      if(!compulsoriesNote.isCompleted){
        console.log('computeFinalEventNote ABORTED with wrong compulsories note', compulsoriesNote);  
        return operationIds;
      }
      note += (compulsoriesNote.noteValue * 0.45);
      precision = compulsoriesNote.noteValue;
      console.log('computeFinalEventNote (compulsories)', compulsoriesNote, note);
    }
    if(EVENT.EVENT.hasRoutine){
      var routineNote = RST_ROUTINE_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where(n => n.judge_id == null)
                    .first();
      if(!routineNote.isCompleted){
        console.log('computeFinalEventNote ABORTED with wrong routine note', routineNote);  
        return operationIds;
      }
      note += (routineNote.note * 0.55);
      precision = note;
      console.log('computeFinalEventNote (routine)', routineNote, note);
    }
    if(EVENT.EVENT.hasBallet){
      var balletNote = RST_BALLET_NOTE.query()
                    .where("competition_id", EVENT.competition_id)
                    .where("round_number", EVENT.round_number)
                    .where("level", EVENT.level)
                    .where("category", EVENT.category)
                    .where("event", EVENT.event)
                    .where("competitor_id", EVENT.current_competitor_id)
                    .where(n => n.judge_id == null)
                    .first();
      if(!balletNote.isCompleted){
        console.log('computeFinalEventNote ABORTED with wrong ballet note', balletNote);  
        return operationIds;
      }
      var pound = EVENT.EVENT.hasCompulsories ? 0.55 : 1;
      note += (balletNote.note * pound);
      ballet = balletNote.note;
      console.log('computeFinalEventNote (ballet)', balletNote, note, pound);
    }
    // Si l'on arrive ici, c'est que toutes les composantes de l'épreuve ont été notées pour le compétiteur en cours et que l'on a donc été en mesure de calculer sa note finale.
    // Il s'agit maintenant de l'enregistrer 
    var upd = { 
      note: note,
      precision_note: precision,
      ballet_note: ballet,
      executionStatus: RST_EVENT_COMPETITOR.ExecutionStatus_Completed,
    };
    console.log('computeFinalEventNote => ready to save', upd)
    var eventCompetitorId = EVENT.CURRENT_COMPETITOR.id;
    RST_EVENT_COMPETITOR.update({ where: eventCompetitorId, data: upd});
    operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT_COMPETITOR", eventCompetitorId, upd, "NOTE"));

    // mais aussi de gérer le classement du compétiteur pour l'épreuve.
    //#region Gestion des classements pour la note finale de routine du compétiteur
    var allRecords = RST_EVENT_COMPETITOR.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("executionStatus", RST_EVENT_COMPETITOR.ExecutionStatus_Completed)
        .get();

      allRecords.sort((a,b) => b.note - a.note);
      var prevRecord = { value:0, rank: 0};
      allRecords.forEach((n, index) => {
        var r = (n.note == prevRecord.value) ? prevRecord.rank : index + 1;
        var rkUpd = { rank: r};
        RST_EVENT_COMPETITOR.update({ where: n.id, data: rkUpd});
        prevRecord = { value: n.note, rank: r};
      });

      var allFederalRecords = allRecords.filter(r => r.isFederal);
      allFederalRecords.sort((a,b) => b.note - a.note);
      prevRecord = { value: 0, rank: 0};
      allFederalRecords.forEach((n, index) => {
        var r = (n.note == prevRecord.value) ? prevRecord.rank : index + 1;
        var frkUpd = { federal_rank: r};
        RST_EVENT_COMPETITOR.update({ where: n.id, data: frkUpd});
        prevRecord = { value: n.note, rank: r};
      })

      if(EVENT.EVENT.hasCompulsories || EVENT.EVENT.hasRoutine){
        allRecords.sort((a,b) => (b.precision_note || 0) - (a.precision_note || 0));
        prevRecord = { value: 0, rank: 0};
        allRecords.forEach((n, index) => {
          var r = ((n.precision_note || 0) == prevRecord.value) ? prevRecord.rank : index + 1;
          var rkUpdP = { precision_rank: r};
          RST_EVENT_COMPETITOR.update({ where: n.id, data: rkUpdP });
          prevRecord = { value: n.precision_note || 0, rank: r};
        });

        allFederalRecords.sort((a,b) => (b.precision_note || 0) - (a.precision_note || 0));
        prevRecord = { value: 0, rank: 0};
        allFederalRecords.forEach((n, index) => {
          var r = ((n.precision_note || 0) == prevRecord.value) ? prevRecord.rank : index + 1;
          var frkUpdP = { precision_federal_rank: r};
          RST_EVENT_COMPETITOR.update({ where: n.id, data: frkUpdP });
          prevRecord = { value: n.precision_note || 0, rank: r};
        });
      }
      if(EVENT.EVENT.hasBallet){
        allRecords.sort((a,b) => (b.ballet_note || 0) - (a.ballet_note || 0));
        prevRecord = { value: 0, rank: 0};
        allRecords.forEach((n, index) => {
          var r = ((n.ballet_note || 0) == prevRecord.value) ? prevRecord.rank : index + 1;
          var rkUpdB = { ballet_rank: r};
          RST_EVENT_COMPETITOR.update({ where: n.id, data: rkUpdB });
          prevRecord = { value: n.ballet_note || 0, rank: r};
        });

        allFederalRecords.sort((a,b) => (b.ballet_note || 0) - (a.ballet_note || 0));
        prevRecord = { value: 0, rank: 0};
        allFederalRecords.forEach((n, index) => {
          var r = ((n.ballet_note || 0) == prevRecord.value) ? prevRecord.rank : index + 1;
          var frkUpdB = { ballet_federal_rank: r};
          RST_EVENT_COMPETITOR.update({ where: n.id, data: frkUpdB });
          prevRecord = { value: n.ballet_note || 0, rank: r};
        });
      }


      RST_EVENT_COMPETITOR.query()
        .where("competition_id", EVENT.competition_id)
        .where("round_number", EVENT.round_number)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where("event", EVENT.event)
        .where("executionStatus", RST_EVENT_COMPETITOR.ExecutionStatus_Completed)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank,
            precision_rank: n.precision_rank,
            precision_federal_rank: n.precision_federal_rank,
            ballet_rank: n.ballet_rank,
            ballet_federal_rank: n.ballet_federal_rank,
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT_COMPETITOR", n.id, updRks, "RANKS"));
        });      
      //#endregion Gestion des classements pour la note finale de routine du compétiteur

      // Associé à la finalisation d'une épreuve pour un compétiteur donné, on peut également tenter de calculer une note globale temporaire et son classement provisoire pour l'ensemble de la compétition.
      var allEventsNotes = RST_EVENT_COMPETITOR.query()
        .where("competition_id", EVENT.competition_id)
        .where("competitor_id", EVENT.current_competitor_id)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        //.where("event", EVENT.event)
        .where("executionStatus", RST_EVENT_COMPETITOR.ExecutionStatus_Completed)
        .get();
      if(allEventsNotes.length > 0){
        var sum = allEventsNotes.map(n => n.note).reduce((p,c) => parseFloat(p) + parseFloat(c));
        var total = sum / allEventsNotes.length;

        var totalPrecision = null;
        var totalBallet = null;

        var allPrecisionEvents = allEventsNotes.filter(n => n.precision_note != null);
        if(allPrecisionEvents.length > 0){
          var sumPrecision = allPrecisionEvents.map(n => n.precision_note || 0).reduce((p,c)=> parseFloat(p) + parseFloat(c));
          totalPrecision = sumPrecision / allPrecisionEvents.length;
        }

        var allBalletEvents = allEventsNotes.filter(n => n.ballet_note != null);
        if(allBalletEvents.length > 0){
          var sumBallet = allBalletEvents.map(n => n.ballet_note || 0).reduce((p,c)=> parseFloat(p) + parseFloat(c));
          totalBallet = sumBallet / allBalletEvents.length;
        }

        upd = { note: total, precision_note: totalPrecision, ballet_note: totalBallet };
        var rstCompetitorId = EVENT.CURRENT_COMPETITOR.RST_COMPETITOR.id;

        RST_COMPETITOR.update({ where: rstCompetitorId, data: upd});
        operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", rstCompetitorId, upd, "NOTE"));
    
        var allCompetitorsForRank = RST_COMPETITOR.query()
          .where("competition_id", EVENT.competition_id)
          .where("level", EVENT.level)
          .where("category", EVENT.category)
          .where(c => c.note != null)
          .get();
        allCompetitorsForRank.sort((a,b) => b.note - a.note);
        prevRecord = { value: 0, rank: 0};
        allCompetitorsForRank.forEach((n, index) => {
          var r = (n.note == prevRecord.value) ? prevRecord.rank : index + 1;
          var rkUpd = { rank: r};
          RST_COMPETITOR.update({ where: n.id, data: rkUpd});
          prevRecord = { value: n.note, rank: r};
        })

        allCompetitorsForRank.sort((a,b) => (b.precision_note || 0) - (a.precision_note || 0));
        prevRecord = { value: 0, rank: 0};
        allCompetitorsForRank.forEach((n, index) => {
          var r = (n.precision_note == prevRecord.value) ? prevRecord.rank : index + 1;
          var rkUpd = { precision_rank: r};
          RST_COMPETITOR.update({ where: n.id, data: rkUpd});
          prevRecord = { value: n.precision_note, rank: r};
        })        

        allCompetitorsForRank.sort((a,b) => (b.ballet_note || 0) - (a.ballet_note || 0));
        prevRecord = { value: 0, rank: 0};
        allCompetitorsForRank.forEach((n, index) => {
          var r = (n.ballet_note == prevRecord.value) ? prevRecord.rank : index + 1;
          var rkUpd = { ballet_rank: r};
          RST_COMPETITOR.update({ where: n.id, data: rkUpd});
          prevRecord = { value: n.ballet_note, rank: r};
        })        

        allFederalRecords = allCompetitorsForRank.filter(r => r.isFederal);
        allFederalRecords.sort((a,b) => b.note - a.note);
        prevRecord = { value: 0, rank: 0};
        allFederalRecords.forEach((n, index) => {
          var r = (n.note == prevRecord.value) ? prevRecord.rank : index + 1;
          var frkUpd = { federal_rank: r};
          RST_COMPETITOR.update({ where: n.id, data: frkUpd});
          prevRecord = { value: n.note, rank: r};
        })

        allFederalRecords.sort((a,b) => (b.precision_note || 0) - (a.precision_note || 0));
        prevRecord = { value: 0, rank: 0};
        allFederalRecords.forEach((n, index) => {
          var r = (n.precision_note == prevRecord.value) ? prevRecord.rank : index + 1;
          var frkUpd = { precision_federal_rank: r};
          RST_COMPETITOR.update({ where: n.id, data: frkUpd});
          prevRecord = { value: n.precision_note, rank: r};
        })

        allFederalRecords.sort((a,b) => (b.ballet_note || 0) - (a.ballet_note || 0));
        prevRecord = { value: 0, rank: 0};
        allFederalRecords.forEach((n, index) => {
          var r = (n.ballet_note == prevRecord.value) ? prevRecord.rank : index + 1;
          var frkUpd = { ballet_federal_rank: r};
          RST_COMPETITOR.update({ where: n.id, data: frkUpd});
          prevRecord = { value: n.ballet_note, rank: r};
        })

        RST_COMPETITOR.query()
        .where("competition_id", EVENT.competition_id)
        .where("level", EVENT.level)
        .where("category", EVENT.category)
        .where(c => c.note != null)
        .get()
        .forEach(n => {
          var updRks = {
            rank: n.rank,
            federal_rank: n.federal_rank,
            precision_rank: n.precision_rank,
            precision_federal_rank: n.precision_federal_rank,
            ballet_rank: n.ballet_rank,
            ballet_federal_rank: n.ballet_federal_rank,
          };
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", n.id, updRks, "RANKS"));
        });  
      }
      return operationIds;
    },

  /**
   * Try to compute the final total note for the current competitor for the whole event (if all judge have given their notes for all the components of the event)
   * and save it if necessary
   * @param {RST_EVENT} EVENT - The event during while the compulsories are executed for the current competitor
   * @returns {Boolean} - true if the saving on server succeeded otherwise false
   */
  async computeAndSaveFinalEventNoteAsync(EVENT)
  {
    console.log('computeAndSaveFinalEventNoteAsync(EVENT)', EVENT);
    if(EVENT.EVENT.hasCompulsories)
      await this.refreshEventCompulsoriesNotesAsync(EVENT);
    if(EVENT.EVENT.hasRoutine)
      await this.refreshEventRoutinesNotesAsync(EVENT);
    if(EVENT.EVENT.hasBallet)
      await this.refreshEventBalletsNotesAsync(EVENT);
    var operationIds = this.computeFinalEventNote(EVENT);
    if(operationIds.length > 0)
      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    return false;
  },

//#endregion EVENT Notes computation (savings)

  

  /** OBSOLETE ? */
  computePrecision(Event, competitor_id)
  {
    var notes = this.getNotes(Event.competition_id, Event.round_number, Event.level, Event.category, competitor_id);
    var rule = Event.RULE;
    var isReady = (!rule.hasCompulsories || notes.compulsories_isCompleted ) && (!rule.hasRoutine || notes.routine_isCompleted);
    //if(!isReady){
    //  SynchroHelper.TryToSynchronize();
    //  return;
    //}
    var compulsories = rule.hasCompulsories ? notes.compulsories_total : 0;
    var routine = rule.hasRoutine ? notes.routine_total : 0;
    var total = (compulsories * (1 - rule.poundOfRoutineWithinPrecision)) + (routine * rule.poundOfRoutineWithinPrecision);
    var change = {
      precision_total : total,
      precision_isCompleted: isReady,
    };
    RST_COMPETITOR_NOTES.update({ where: notes.id, data: change});
    SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", notes.id, change, "PRECISION");
    console.log('computePrecision => (change, notes)', { changes: change, notes: notes});
    this.sortCompetitorNotesResults(Event, "precision_total", (index)=>{ return { precision_total_rank: index, precision_total_federal_rank: index };}, "PRECISION_RANKS")

    this.computeOverall(Event, competitor_id);
  },

  /** OBSOLETE ? */
  computeOverall(Event, competitor_id){
    var notes = this.getNotes(Event.competition_id, Event.round_number, Event.level, Event.category, competitor_id);
    var rule = Event.RULE;
    var isReady = (!rule.hasPrecision || notes.precision_isCompleted ) && (!rule.hasBallet || notes.ballet_isCompleted);
    //if(!isReady)
    //{
    //    SynchroHelper.TryToSynchronize();
    //    return;
    //}
    var precision = rule.hasPrecision ? notes.precision_total : 0;
    var ballet = rule.hasBallet ? notes.ballet_total : 0;
    var total = (precision * rule.poundOfPrecisionWithinOverall) + (ballet * rule.poundOfBalletWithinOverall);
    var change = {
      overall : total,
      isCompleted : isReady
    };
    RST_COMPETITOR_NOTES.update({ where: notes.id, data: change});
    SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", notes.id, change, "OVERALL");

    this.sortCompetitorNotesResults(Event, "overall", (index)=>{ return { overall_rank: index, overall_federal_rank: index };}, "OVERALL_RANKS");
  },



  /** OBSOLETE ? */
  sortCompetitorCompulsoryNoteResults(Event, compulsory_id, changeExpression, changeName)
  {
    var notes = RST_COMPETITOR_NOTES.query()
        .where("competition_id", Event.competition_id)
        .where('round_number', Event.round_number)
        .where("level", Event.level)
        .where("category", Event.category)
        .where("compulsory_id", compulsory_id)
 //       .orderBy("noteValue", "desc")
        .get()
        .map(item => {
          if(item.noteValue == null) item.noteValue = 0;
          return item;
        });
    notes.sort((a,b) => {
      return b.noteValue - a.noteValue;
    });
    notes
        .map((item, index) => {
          return { note_id: item.id, change: changeExpression(index+1) };
        })
        .forEach((elem) => {
          RST_COMPETITOR_COMPULSORY_NOTE.update({ where: elem.note_id, data: elem.change });
          SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_COMPULSORY_NOTE", elem.note_id, elem.change, changeName);
        });
  },

  /** OBSOLETE ? */
  sortCompetitorNotesResults(Event, sortExpression, changeExpression, changeName, withSynchro = true)
  {
//    var maxIndex = Event.EFFECTIVE_COMPETITORS.length;
    var notes = RST_COMPETITOR_NOTES.query()
        .where("competition_id", Event.competition_id)
        .where('round_number', Event.round_number)
        .where("level", Event.level)
        .where("category", Event.category)
        .get()
        .map(item => {
          if(item.compulsories_total == null) item.compulsories_total = 0;
          if(item.routine_execution == null) item.routine_execution = 0;
          if(item.routine_content == null) item.routine_content = 0;
          if(item.precision_total == null) item.precision_total = 0;
          if(item.ballet_execution == null) item.ballet_execution = 0;
          if(item.ballet_choreo == null) item.ballet_choreo = 0;
          if(item.ballet_total == null) item.ballet_total = 0;
          if(item.overall == null) item.overall = 0;
          return item;
        });
    notes.sort((a, b) => {
      switch(sortExpression)
      {
        case "compulsories_total": return b.compulsories_total - a.compulsories_total;
        case "routine_execution": return b.routine_execution - a.routine_execution;
        case "routine_content": return b.routine_content - a.routine_content;
        case "routine_total": return b.routine_total - a.routine_total;
        case "precision_total": return b.precision_total - a.precision_total;
        case "ballet_execution": return b.ballet_execution - a.ballet_execution;
        case "ballet_choreo": return b.ballet_choreo - a.ballet_choreo;
        case "ballet_total": return b.ballet_total - a.ballet_total;
        case "overall": return b.overall - a.overall;
        default:
          throw "Not managed sortExpression '" + sortExpression + "'";
      }
    });
    notes
//        .orderBy(sortExpression, "desc")
//        .get()
        .map((item, index) => {
          return { note_id: item.id, change: changeExpression(index+1) };
        })
        .forEach((elem) => {
          RST_COMPETITOR_NOTES.update({ where: elem.note_id, data: elem.change });
          if(withSynchro)
            SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", elem.note_id, elem.change, changeName);
        });
  },

  compare(a,b){
    if(a == null && b == null)
      return 0;

    if(a == null)
      return 1;

    if(b == null)
      return -1;

    if( a < b)
      return -1;

    if( a > b)
      return 1;

    return 0;
  }
}

export { CompetitorsNotesHelper }
